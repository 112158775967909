<template>
     <div class='wrap'>
        <div class='rec'>
            <div class='rect'>请按住滑块拖动到右侧验证
                <div class='silde' draggable="false"><img draggable="false" class="slideHandle" src="imgs/--1.png" alt=""></div>    
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            var that=this;
            //获取事件
            var wrap=document.querySelector(".wrap")
            var silde = document.querySelector('.silde');
            var rec = document.querySelector('.rec');
            var rect= document.querySelector('.rect');
            var img= document.querySelector('.slideHandle');
            // console.log(getComputedStyle(wrap).width)
            // console.log(getComputedStyle(img).width)
            var minusX;  //保存变化的 X坐标（全局变量）
            var limitVal=parseFloat(getComputedStyle(wrap).width)-parseFloat(getComputedStyle(img).width)
            //注册事件
            silde.onmousedown = function(e) {    //鼠标点击事件，点击之后执行函数，获得点击位置的X坐标
                var initX = e.clientX;    //保存初始按下位置的 X坐标；
                console.log(11,e);    //用以测试
                rect.onmousemove = function(e) {        //鼠标移动事件
                    var moveX = e.clientX;
                    // var minusX = moveX - initX;    //变化的坐标（要注意作用域的问题，在这里面定义变量，在这个函数之外的函数就没法使用，所以要将minusX变成全局变量）
                    minusX = moveX - initX;
                    console.log(33)
                    //这里注意一下，获得的minusX只是一个差值，没有单位想让 滑块的位置改变还需要加上 单位px
                    //这个时候滑块会跟随鼠标整个页面一行的跑，价格条件判段，限制 滑块移动的区域不可以超过边框，保持left<=0。
                    if(minusX < 0) {
                        // silde.style.left = '0';
                        minusX = 0;
                    }
                    
                    // if(minusX > 350) { //判断最大值
                    //     // silde.style.left = '251';
                    //     // 这里面的距离用边框长度减去 滑块的长度 300-49
                    //     minusX = 350;
                    //     console.log('我到头了');
                    //     silde.style.cursor="auto"
                    //     that.$emit("slideOver")
                    // }

                    if(minusX > limitVal) { //判断最大值
                        // silde.style.left = '251';
                        // 这里面的距离用边框长度减去 滑块的长度 300-49
                        minusX = limitVal;
                        console.log('我到头了');
                        silde.style.cursor="auto"
                        that.$emit("slideOver")
                    }
                    silde.style.left = minusX + 'px'; 
                    rec.style.width = minusX + 'px';
                    // if(minusX >= 350) {
                    //     rect.style.color = 'white';
                    //     img.src = 'imgs/--2.png';
                    //     rect.onmousemove = null;
                    //     silde.onmousedown = null;
                    //     // rect.innerHTML = '验证成功';
                    // }
                    if(minusX >= limitVal) {
                        rect.style.color = 'white';
                        img.src = 'imgs/--2.png';
                        rect.onmousemove = null;
                        silde.onmousedown = null;
                        // rect.innerHTML = '验证成功';
                    }
                    // console.log(222,e,minusX);    //用以测试
                }
            }
            // wrap.onmouseout=function(e){
            //     console.log(e)
            //     rect.onmousemove = null;    //不允许鼠标移动事件发生
            //     console.log(222);
            //     if(minusX < 350) { //如果没有到头
            //         img.src = 'imgs/--1.png';
            //         silde.style.left = 0;    //设置一个 left值
            //         rec.style.width = 0;    //绿色背景层设置宽度
            //     }
            // }
            rect.onmouseup = function () {    //鼠标抬起事件
                rect.onmousemove = null;    //不允许鼠标移动事件发生
                console.log(111);
                // if(minusX < 350) { //如果没有到头
                //     img.src = 'imgs/--1.png';
                //     silde.style.left = 0;    //设置一个 left值
                //     rec.style.width = 0;    //绿色背景层设置宽度
                // }
                if(minusX < limitVal) { //如果没有到头
                    img.src = 'imgs/--1.png';
                    silde.style.left = 0;    //设置一个 left值
                    rec.style.width = 0;    //绿色背景层设置宽度
                }
            }
        },
    }
</script>

<style lang="less" scoped>
.wrap{
    width:400px;
    height: 50px;
    background-color:#e8e8e8;
    // margin:100px auto;
    text-align: center;
    line-height: 50px;
    /*border-radius: 7px;*/
    position:relative;
}
.rect{
    position:relative;
    width:400px;
    height:100%;
}
.rec{
    position:absolute;
    top:0;
    left:0;
    width:0;
    height:100%;
    background: #00b894;
}
.silde{
    position:absolute;
    top:0;
    left:0;
    z-index: 11;
    /*在这里面，当设置长宽为40px时在加上边框1px就会超出 40px。
    可以使用怪异盒模型，怪异盒模型会使盒子的宽高包括边框，操持40px；*/
    box-sizing:border-box;
    width:50px;
    height:50px;
    background: #fff;
    border:1px solid #ccc;
    cursor: move;
}
.silde>img{
    width: 50px;
    height: 50px;
}
</style>